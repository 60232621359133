import styled from "styled-components";

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 50%;
  width: 400px;

  &:nth-of-type(1) {
    margin-bottom: 50px;
    margin-top: 180px;
  }
  &:nth-of-type(2) {
    margin-bottom: 50px;
    margin-top: 180px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .text {
      margin: 0;
      background-color: #1192c9;
      width: 300px;
      color: white;

      .location {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .title {
      border-radius: 20px 20px 0px 0px;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      font-size: 26px;
      text-transform: uppercase;
      height: 25px;
      line-height: 25px;
    }

    .party {
      border-radius: 0px 0px 20px 20px;
      padding: 10px;
      text-align: center;
      font-size: 14px;
    }

    .slider {
      height: 270px;
      width: 650px;

      .slick-slider {
        height: 280px !important;
      }

      .slick-track {
        & img {
          height: 270px;
          width: 450px;
          object-fit: cover;
        }

        .slick-slide {
          height: 200px;

          & div {
            border-radius: 20px;
            & img {
            }
          }

          & img {
            height: 200px;
            margin-top: 35px;
            cursor: pointer;
          }
        }

        .slick-cloned {
          height: 200px;
          & img {
            height: 200px;
            margin-top: 35px;
            cursor: pointer;
          }
        }

        .slick-current {
          width: 450px !important;
          margin-left: -122px !important;
          & img {
            height: 270px;
            margin-top: 0px;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }

      .css-doq0dk {
        position: relative;

        & div:nth-child(odd) {
          height: 350px;
          img {
            height: 350px;
          }
        }
      }

      .css-1qzevvg {
        width: 450px;
        & img:nth-of-type(1) {
          position: absolute;
          top: -168px;
          left: -124px;
          cursor: pointer;
          opacity: 0.2;
        }

        & img:nth-of-type(2) {
          position: absolute;
          top: -168px;
          right: -124px;
          cursor: pointer;
          opacity: 0.2;
        }

        & img:nth-of-type(1):hover,
        & img:nth-of-type(2):hover {
          opacity: 1;
        }
      }

      .css-1fzpoyk {
        opacity: 1 !important;
        img {
          height: 270px;
          width: 450px;
          object-fit: cover;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .styles-module_wrapper__1I_qj {
    z-index: 100 !important;
  }

  @media screen and (max-width: 700px) {
    width: 300px;

    &:nth-of-type(1) {
      margin-bottom: 50px;
      margin-top: 50px;
    }
    &:nth-of-type(2) {
      margin-bottom: 50px;
      margin-top: 0px;
    }

    &:nth-of-type(4) {
      margin-top: 50px;
    }

    .inner {
      .text {
        margin: 0;
        background-color: #1192c9;
        width: 300px;
        color: white;
      }
      .slider {
        width: 320px;
        height: 200px;

        .slick-slider {
          height: 200px !important;
        }

        .slick-track {
          & img {
            height: 200px;
            width: 450px;
            object-fit: cover;
          }

          .slick-slide {
            height: 200px;

            & div {
              border-radius: 0px;
              & img {
              }
            }

            & img {
              height: 200px;
              margin-top: 0px;
            }
          }

          .slick-cloned {
            height: 200px;
            & img {
              height: 200px;
              margin-top: 0px;
            }
          }

          .slick-current {
            width: 450px !important;
            margin-left: -122px !important;
            & img {
              height: 200px;
              margin-top: 0px;
              border-radius: 0px;
            }
          }
        }

        .css-doq0dk {
          position: relative;

          & div:nth-child(odd) {
            height: 300px;
            img {
              height: 300px;
            }
          }
        }

        .css-1fzpoyk {
          opacity: 1 !important;
          img {
            height: 200px;
            width: 280px;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .css-1qzevvg {
          width: 450px;
          & img:nth-of-type(1) {
            position: absolute;
            top: -135px;
            left: -35px;
            cursor: pointer;
            opacity: 0.6;
          }

          & img:nth-of-type(2) {
            position: absolute;
            top: -135px;
            right: -35px;
            cursor: pointer;
            opacity: 0.6;
          }

          & img:nth-of-type(1):hover,
          & img:nth-of-type(2):hover {
            opacity: 1;
          }
        }

        .css-1qzevvg {
          width: 300px;
        }
      }
    }
  }

  @media screen and (min-width: 701px) and (max-width: 1300px) {
    &:nth-of-type(1) {
      margin-bottom: 50px;
      margin-top: 50px;
    }
    &:nth-of-type(2) {
      margin-bottom: 50px;
      margin-top: 0px;
    }

    &:nth-of-type(4) {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 1500px) {
    &:nth-of-type(1) {
      margin-bottom: 50px;
      margin-top: 250px;
    }
    &:nth-of-type(2) {
      margin-bottom: 50px;
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 360px) {
    .inner {
      .text {
        margin: 0;
        background-color: #1192c9;
        width: 300px;
        color: white;
      }
      .slider {
        width: 320px;
        height: 200px;

        .slick-slider {
          .slick-prev {
            left: -20px;
          }

          .slick-next {
            right: -20px;
          }

          .css-1qzevvg {
            width: 450px;
            & img:nth-of-type(1) {
              position: absolute;
              top: -135px;
              left: -5px;
              cursor: pointer;
              opacity: 0.6;
            }

            & img:nth-of-type(2) {
              position: absolute;
              top: -135px;
              right: -15px;
              cursor: pointer;
              opacity: 0.6;
            }

            & img:nth-of-type(1):hover,
            & img:nth-of-type(2):hover {
              opacity: 1;
            }
          }
        }
        .css-1qzevvg {
          width: 300px;
        }
      }
    }
  }
`;
