import React, { useEffect, useState, FC } from "react";
import { CountContainer } from "./index.style";
import FlipCountdown from "@rumess/react-flip-countdown";

interface TimeProps {
  deadline: any;
  line?: boolean;
}

const CountdownTimer: FC<TimeProps> = ({ deadline, line }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = (num: number) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline: any) => {
    const time = Date.parse(deadline) - Date.parse(new Date() as any);

    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      // setExpired message
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);

    return () => getTimeUntil(deadline);
  }, [deadline]);

  console.log();

  return (
    <CountContainer>
      {/*  <div>
        <div className="Clock-days">
          <span className="bg">{leading0(days)}</span> dana
        </div>
        <div className="Clock-hours">
          <span className="bg">{leading0(hours)}</span> sati
        </div>
        <div className="Clock-minutes">
          <span className="bg">{leading0(minutes)}</span> minuta
        </div>
        <div className="Clock-seconds">
          <span className="bg">{leading0(seconds)}</span> sekundi do ŽURKEEE!
        </div>
      </div> */}
      {!line && (
        <div>
          <FlipCountdown endAt={deadline} />
          <p>DO ŽURKEEEE!</p>
        </div>
      )}
    </CountContainer>
  );
};

export default CountdownTimer;
