import React, { FC } from "react";
import { LineUpWrapper } from "./index.style";

interface Props {
  isViewerOpen: boolean;
}

export const LineUp: FC<Props> = ({ isViewerOpen }) => {
  return (
    <LineUpWrapper isViewerOpen={isViewerOpen}>
      <div className="lineup">
        <div className="first_lineup">
          <div className="date">
            {/* <p>Četvrtak</p>
            <p>4. JUL</p> */}
          </div>
          <div className="disks">
            <div className="disk">
              <img loading="lazy" src="/images/disks/1.webp" />
              <p>Kiki Lesandrić & Piloti</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/2.webp" />
              <p>Tijana Dapčević</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/3.webp" />
              <p>Amadeus bend</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/10.webp" />
              <p>Dj Lea Davogić (hip hop / RnB 2000's)</p>
            </div>

            <div className="disk">
              <img loading="lazy" src="/images/disks/4.webp" />
              <p>Seka Aleksić</p>
            </div>

            <div className="disk">
              <img loading="lazy" src="/images/disks/5.webp" />
              <p>Ana Kokić</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/6.webp" />
              <p>Sanja Djordjević</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/7.webp" />
              <p>Dušan Svilar</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/11.webp" />
              <p>Dj Marchez</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/12.webp" />
              <p>Dj Colak</p>
            </div>

            <div className="disk">
              <img loading="lazy" src="/images/disks/8.webp" />
              <p>Tina Ivanović</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/9.webp" />
              <p>Olja Karleuša</p>
            </div>
            <div className="disk">
              <img loading="lazy" src="/images/disks/13.webp" />
              <p>Kluboslavija / MKDSL</p>
            </div>
          </div>
        </div>
      </div>
    </LineUpWrapper>
  );
};
