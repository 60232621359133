import React, { useEffect, useState } from "react";
import CountdownTimer from "../../components/Countdown/CountdownTimer";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { Comment } from "../../components/Comment/Comment";
import { Container } from "./index.style";
import { useLocation } from "react-router-dom";
import { Modal } from "../../components/Comment/Modal";
import moment from "moment";
import { ThankYouModal } from "../../components/Comment/ThankYouModal";

export const Ispovesti = () => {
  let deadline = "2023-06-29 22:00:00";
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [addTestemonial, setAddTestemonial] = useState(false);
  const [thankYou, setThankYou] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const format = moment().format("YYYY-MM-DD H:m:s");

  const line = format > moment().format(deadline);

  return (
    <Container>
      <CountdownTimer deadline={deadline} line={line} />
      <NavBar isViewerOpen={isViewerOpen} modal={modal} setModal={setModal} />
      <div className="comments_container">
        <Comment
          addTestemonial={addTestemonial}
          setAddTestemonial={setAddTestemonial}
          thankYou={thankYou}
          setThankYou={setThankYou}
        />
      </div>
      <Footer modal={modal} setModal={setModal} />
      {addTestemonial && (
        <Modal
          setAddTestemonial={setAddTestemonial}
          setThankYou={setThankYou}
        />
      )}
      {thankYou && <ThankYouModal setThankYou={setThankYou} />}
    </Container>
  );
};
