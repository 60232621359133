import React, { useState } from "react";
import { BravoProvider } from "../../context/StateProvider";
import CountdownTimer from "../../components/Countdown/CountdownTimer";
import { NavBar } from "../../components/NavBar";
import { HomePage } from "../HomePage";
import { AboutUs } from "../AboutUS";
import { Party } from "../Party";
import { Element } from "react-scroll";
import { Festival } from "../Festival";
import { LineUp } from "../LineUp/LineUp";
import { Footer } from "../../components/Footer";
import moment from "moment";

export const Home = () => {
  let deadline = "2023-06-29 22:00:00";
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const format = moment().format("YYYY-MM-DD H:m:s");

  const line = format > moment().format(deadline);

  return (
    <BravoProvider>
      <div>
        <CountdownTimer deadline={deadline} line={line} />
        <NavBar isViewerOpen={isViewerOpen} modal={modal} setModal={setModal} />
        <Element name="home">
          <HomePage />
        </Element>
        <AboutUs isViewerOpen={isViewerOpen} />
        <Party isViewerOpen={isViewerOpen} setIsViewerOpen={setIsViewerOpen} />
        <Element name="festival">
          <Festival isViewerOpen={isViewerOpen} />
        </Element>
        <Element name="lineup">
          <LineUp isViewerOpen={isViewerOpen} />
        </Element>
        <Footer modal={modal} setModal={setModal} />
      </div>
    </BravoProvider>
  );
};
