import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { ShareModalWrapper } from "./index.style";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  setShare: (e: boolean) => void;
  id: number;
}

export const ShareModal: React.FC<Props> = ({ setShare, id }) => {
  const [copied, setCopied] = useState(false);
  const refs = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (refs.current && !refs.current.contains(e.target)) {
      setShare(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const path = window.location.href;
  const url = `${path}?page=${1}&id=${id}`;

  return (
    <ShareModalWrapper>
      <div className="container" ref={refs}>
        <p>Podeli na</p>
        <div>
          <WhatsappShareButton url="/">
            <WhatsappIcon size={48} round={true} />{" "}
          </WhatsappShareButton>
          <ViberShareButton url="/">
            <ViberIcon size={48} round={true} />{" "}
          </ViberShareButton>
          <TwitterShareButton url="/">
            <TwitterIcon size={48} round={true} />{" "}
          </TwitterShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={48} round={true} />{" "}
          </TelegramShareButton>
          <FacebookShareButton url="/">
            <FacebookIcon size={48} round={true} />{" "}
          </FacebookShareButton>
        </div>
        <div className="copy">
          {copied ? (
            <input value="Copied url!" disabled />
          ) : (
            <input value={url} disabled />
          )}
          <CopyToClipboard
            text={url}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            <img
              src="/images/ispovesti/copy.svg"
              height={38}
              width={38}
              alt="copy"
            />
          </CopyToClipboard>
        </div>
      </div>
    </ShareModalWrapper>
  );
};
