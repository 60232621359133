import styled from "styled-components";

interface Props {
  active?: boolean;
  chosenSort?: boolean;
  active2?: boolean;
}

export const Wrapper = styled.div<Props>`
  min-height: 150px;
  max-width: 1200px;
  margin: 0 auto;
  background-size: cover;
  padding-bottom: 80px;

  .pulse {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 0%, 52.54901960784314%);
    }

    100% {
      background-color: hsl(0, 0%, 24.705882352941178%);
    }
  }

  & h1 {
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-align: center;
  }

  .ispovesti_header {
    max-width: 770px;
    margin: 48px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters {
      display: flex;
      font-size: 14px;
      //width: 100%;
      justify-content: space-between;
      gap: 16px;

      & div:nth-of-type(1) {
        display: flex;
        gap: 16px;
        justify-content: center;
      }

      .dropdown {
        display: inline-block;
        position: relative;
        min-width: 160px;
      }

      .dd-button {
        display: inline-block;
        border: 1px solid #ee8421;
        background-color: ${(props) => props.active && "#ee8421"};
        border-radius: 12px;
        padding: 12px 54px 12px 18px;
        color: white;
        cursor: pointer;
        white-space: nowrap;
      }

      .dd-input {
        height: 10px;
        width: 16px;
        position: absolute;
        right: 18px;
        top: 18px;
        cursor: pointer;
      }

      .dd-menu {
        position: absolute;
        top: 60px;
        right: 0;
        border: 1px solid white;
        border-radius: 4px;
        padding: 0;
        margin: 2px 0 0 0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        background-color: #1a1a1a;
        list-style-type: none;
        z-index: 16;
        color: white;
        width: 160px;

        .active {
          color: #ee8421;
          text-decoration: underline;
        }
      }

      .dd-menu li {
        padding: 10px 20px;
        cursor: pointer;
        white-space: nowrap;
      }

      .dd-menu li a {
        display: block;
        margin: -10px -20px;
        padding: 10px 20px;
      }

      .dropdown2 {
        display: inline-block;
        position: relative;
        min-width: 160px;
      }

      .dd-button2 {
        display: inline-block;
        border: 1px solid #ee8421;
        background-color: ${(props) => props.active2 && "#ee8421"};
        border-radius: 12px;
        padding: 12px 54px 12px 18px;
        color: white;
        cursor: pointer;
        white-space: nowrap;
      }

      .dd-input2 {
        height: 10px;
        width: 16px;
        position: absolute;
        right: 18px;
        top: 18px;
        cursor: pointer;
      }

      .dd-menu2 {
        position: absolute;
        top: 60px;
        right: 0;
        border: 1px solid white;
        border-radius: 4px;
        padding: 0;
        margin: 2px 0 0 0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        background-color: #1a1a1a;
        list-style-type: none;
        z-index: 16;
        color: white;

        .active {
          color: #ee8421;
          text-decoration: underline;
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .inactive {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }

      .dd-menu2 li {
        padding: 10px 20px;
        cursor: pointer;
        white-space: nowrap;
      }

      .dd-menu2 li:hover {
        color: #ee8421;
        text-decoration: underline;
      }

      .dd-menu2 li a {
        display: block;
        margin: -10px -20px;
        padding: 10px 20px;
      }

      .add_testemonial {
        border: none;
        outline: none;
        background: linear-gradient(to right, #ffa63b, #dd5e07);
        color: white;
        border-radius: 10px;
        padding: 12px;
        min-width: 150px;
        cursor: pointer;
      }
    }
  }

  .page_btns {
    display: flex;
    height: 44px;
    justify-content: center;
    gap: 18px;
    margin-top: 50px;

    .prev_page_btn {
      border: 1px solid #dd5e07;
      display: flex;
      height: 45px;
      width: 45px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
    }

    .next_page_btn {
      width: 170px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 801px) {
    padding: 60px 20px;
    .ispovesti_header {
      h1 {
        width: 100%;
        text-align: center;
        margin-top: -30px;
        margin-bottom: 24px;
      }
      .filters {
        .dd-menu2 {
          right: 10px;
          top: 60px;
        }
      }
    }
  }

  @media screen and (max-width: 661px) {
    .ispovesti_header {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;

      .filters {
        flex-wrap: wrap;
        .dd-menu {
          right: unset;
          left: 0;
        }

        .dropdown {
          width: 45%;
        }
        .dropdown2 {
          width: 45%;
        }

        .add_testemonial {
          width: 45%;
        }
      }
    }
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  align-items: center;

  .add_new {
    position: fixed;
    right: 50px;
    z-index: 15;
    cursor: pointer;
    top: 50%;
  }

  .single_wrapper {
    width: 770px;
    min-height: 200px;
    max-height: 740px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;

    .spiral {
      position: absolute;
      top: -6px;
      left: 36px;
    }

    .inner_comment {
      & p {
        margin: 0;
      }
      padding: 32px 15px 20px;
      .comment_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #9d9d9e;
        font-size: 16px;
      }
      & > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        .comment_content {
          margin: 16px 0;
          font-size: 18px;
          font-weight: normal;
          line-height: 30px;
          font-style: normal;
          text-align: left;
          color: #ffffff;
        }

        .more_comment {
          display: flex;
          gap: 8px;
          align-items: baseline;
          color: #9d9d9e;
          align-self: flex-end;
          cursor: pointer;
        }
      }

      .divider {
        border: 1px solid #3b3b3b;
      }

      .comment_footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .comment_info {
          display: flex;
          gap: 12px;

          .info_1,
          .info_2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 38px;
            min-width: 38px;
            background-color: #2a2a2a;
            border-radius: 5px;
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            text-align: left;
            color: #9d9d9e;
          }

          #liked_true {
            border: 1px solid #09b529;
          }

          #disliked_true {
            border: 1px solid #ff4d4d;
          }
        }

        & img {
          cursor: pointer;
        }
      }

      .write_comment {
        margin-top: 44px;
        height: 46px;
        & input {
          border: 1px solid #707070;
          border-radius: 5px;
          background: #2a2a2a;
          padding: 14px 16px;
          width: calc(100% - 32px);
          color: #707070;
        }

        & input:focus {
          outline: none;
        }
      }
    }
  }

  @media screen and (max-width: 801px) {
    .single_wrapper {
      width: 100%;
      max-height: unset;

      .spiral {
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
      }

      .inner_comment {
        & > div:nth-of-type(2) {
          .comment_content {
            font-size: 13px !important;
            line-height: 20px;
          }
        }
      }
    }
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .single_wrapper {
    width: 770px;
    min-height: 300px;
    max-height: 740px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;

    .spiral {
      position: absolute;
      top: -6px;
      left: 36px;
    }

    .inner_comment {
      padding: 32px;

      textarea {
        width: 100%;
        resize: none;
        border: 1px solid #707070;
        border-radius: 5px;
        background: #2a2a2a;
        padding: 14px 16px;
        width: calc(100% - 32px);
        color: #ffffff;
        font-family: "DM Sans";
      }

      & textarea:focus {
        outline: none;
      }

      .modal_footer {
        display: flex;
        justify-content: space-between;
        .dropdown {
          display: inline-block;
          position: relative;
          margin-top: 16px;
        }

        .dd-button {
          display: inline-block;
          border: 1px solid #ee8421;
          background-color: #ee8421;
          border-radius: 12px;
          padding: 10px 54px 10px 18px;
          color: white;
          cursor: pointer;
          white-space: nowrap;
        }

        .dd-input {
          height: 10px;
          width: 16px;
          position: absolute;
          right: 18px;
          top: 18px;
          cursor: pointer;
        }

        .dd-menu {
          position: absolute;
          top: 60px;
          right: 0;
          border: 1px solid white;
          border-radius: 4px;
          padding: 0;
          margin: 2px 0 0 0;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          background-color: #1a1a1a;
          list-style-type: none;
          z-index: 16;
          color: white;
        }

        .dd-menu li {
          padding: 10px 20px;
          cursor: pointer;
          white-space: nowrap;
        }

        .dd-menu li a {
          display: block;
          margin: -10px -20px;
          padding: 10px 20px;
        }

        .add_testemonial {
          border: none;
          outline: none;
          background: linear-gradient(to right, #ffa63b, #dd5e07);
          color: white;
          border-radius: 10px;
          padding: 12px;
          min-width: 120px;
          cursor: pointer;
          margin-top: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 801px) {
    .single_wrapper {
      width: 90%;
      max-height: unset;
      margin: 0 auto;

      .spiral {
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
      }

      .inner_comment {
        padding: 32px 16px;
      }
    }
  }
`;

export const ShareModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .add_testemonial {
    border: none;
    outline: none;
    background: linear-gradient(to right, #ffa63b, #dd5e07);
    color: white;
    border-radius: 10px;
    padding: 12px;
    min-width: 120px;
    cursor: pointer;
    margin-top: 16px;
    max-width: 180px;
  }

  .container {
    min-width: 350px;
    min-height: 150px;
    max-height: 740px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      gap: 16px;
    }

    & p {
      align-self: flex-start;
      color: white;
      font-size: 32px;
    }

    .copy {
      & img {
        cursor: pointer;
      }
      input {
        width: 100%;
        resize: none;
        border: 1px solid #707070;
        border-radius: 5px;
        background: #2a2a2a;
        padding: 14px 16px;
        width: calc(100% - 32px);
        color: #707070;
        font-family: "DM Sans";
        cursor: pointer;
      }

      & input:focus {
        outline: none;
      }
    }
  }

  .container2 {
    min-width: 350px;
    min-height: 150px;
    max-height: 740px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      gap: 16px;
    }

    & p {
      align-self: flex-start;
      color: white;
      font-size: 32px;
    }

    .copy {
      & img {
        cursor: pointer;
      }
      input {
        width: 100%;
        resize: none;
        border: 1px solid #707070;
        border-radius: 5px;
        background: #2a2a2a;
        padding: 14px 16px;
        width: calc(100% - 32px);
        color: #707070;
        font-family: "DM Sans";
        cursor: pointer;
      }

      & input:focus {
        outline: none;
      }
    }
  }

  @media screen and (max-width: 601px) {
    .container {
      min-width: unset;
      max-width: 300px;

      & > div {
        flex-wrap: wrap;
      }
    }
    .container2 {
      min-width: unset;
      max-width: 300px;
    }
  }
`;
