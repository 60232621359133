import styled from "styled-components";

export const PartyWrapper = styled.div`
  background-image: url("/images/party.webp");
  background-size: cover;
  margin-top: -130px;
  padding-bottom: 220px;
  position: relative;
  z-index: 9;

  @media screen and (min-width: 1500px) {
    margin-top: -200px;
    padding-bottom: 250px;
  }

  @media screen and (min-width: 2300px) {
    background-size: contain;
  }
`;

export const PartyContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  //height: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: baseline;

  // padding: 250px 0 50px 0px;

  @media screen and (max-width: 600px) {
    height: 100%;
    padding: 50px 0;
  }

  @media screen and (min-width: 601px) and (max-width: 1300px) {
    max-width: 400px;
    height: 100%;
    padding: 100px 0;
    margin-top: -150px;
  }
`;
