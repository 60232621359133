import { FC, useCallback, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { SliderContainer } from "../../components/City/index.style";
import { PartyContainer, PartyWrapper } from "./Party.style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Element } from "react-scroll";

interface Props {
  isViewerOpen: boolean;
  setIsViewerOpen: (e: boolean) => void;
}

export const Party: FC<Props> = ({ isViewerOpen, setIsViewerOpen }) => {
  const [currentImage, setCurrentImage] = useState(-1);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(-1);
    setIsViewerOpen(false);
  };

  const imag = [
    "/images/ng/ng1.jpg",
    "/images/ng/ng2.jpg",
    "/images/ng/ng3.jpg",
    "/images/ng/ng4.jpg",
    "/images/ng/ng5.jpg",
    "/images/ng/ng6.jpg",
    "/images/ng/ng7.jpg",
    "/images/ng/ng8.jpg",
    "/images/ng/ng9.jpg",
    "/images/msk/msk1.jpg",
    "/images/msk/msk2.jpg",
    "/images/msk/msk3.jpg",
    "/images/msk/msk4.jpg",
    "/images/msk/msk5.jpg",
    "/images/city/9.webp",
    "/images/city/10.webp",
    "/images/city/11.webp",
    "/images/city/12.webp",
    "/images/kg/kg1.jpg",
    "/images/kg/kg2.jpg",
    "/images/kg/kg3.jpg",
    "/images/kg/kg4.jpg",
    "/images/kg/kg5.jpg",
    "/images/kg/kg6.jpg",

    "/images/city/1.webp",
    "/images/city/2.webp",
    "/images/city/3.webp",
    "/images/city/4.webp",
  ];

  var settings = {
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 300,
    draggable: false,
    swipeToSlide: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (isViewerOpen) {
      document.body.style.overflow = "hidden";
    } else if (!isViewerOpen) {
      document.body.style.overflow = "unset";
    }
  }, [isViewerOpen]);

  return (
    <PartyWrapper>
      <Element name="zurke">
        <PartyContainer>
          <SliderContainer>
            <div className="inner">
              <p className="text title">Beograd</p>

              <div className="slider center">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng1.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(0)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng2.jpg"
                      alt="2"
                      className="partyImg"
                      onClick={() => openImageViewer(1)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng3.jpg"
                      alt="3"
                      className="partyImg"
                      onClick={() => openImageViewer(2)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng4.jpg"
                      alt="4"
                      className="partyImg"
                      onClick={() => openImageViewer(3)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng5.jpg"
                      alt="5"
                      className="partyImg"
                      onClick={() => openImageViewer(4)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng6.jpg"
                      alt="6"
                      className="partyImg"
                      onClick={() => openImageViewer(5)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng7.jpg"
                      alt="7"
                      className="partyImg"
                      onClick={() => openImageViewer(6)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng8.jpg"
                      alt="8"
                      className="partyImg"
                      onClick={() => openImageViewer(7)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/ng/ng9.jpg"
                      alt="9"
                      className="partyImg"
                      onClick={() => openImageViewer(8)}
                    />
                  </div>
                </Slider>
              </div>

              <p className="text party">
                Sledeća žurka: <span className="location">Sajam, 31. DECEMBAR</span>
              </p>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={imag}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </SliderContainer>

          <SliderContainer>
            <div className="inner">
              <p className="text title">Novi Sad</p>
              <div className="slider center">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/msk/msk1.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(9)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/msk/msk2.jpg"
                      alt="2"
                      className="partyImg"
                      onClick={() => openImageViewer(10)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/msk/msk3.jpg"
                      alt="3"
                      className="partyImg"
                      onClick={() => openImageViewer(11)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/msk/msk4.jpg"
                      alt="4"
                      className="partyImg"
                      onClick={() => openImageViewer(12)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/msk/msk5.jpg"
                      alt="5"
                      className="partyImg"
                      onClick={() => openImageViewer(13)}
                    />
                  </div>
                </Slider>
              </div>
              <p className="text party">
                Sledeća žurka: <span className="location">SAJAM 26. OKTOBAR</span>{" "}
              </p>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={imag}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </SliderContainer>

          <SliderContainer>
            <div className="inner">
              <p className="text title">Niš</p>
              <div className="slider center">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/9.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(14)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/10.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(15)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/11.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(16)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/12.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(17)}
                    />
                  </div>
                </Slider>
              </div>
              <p className="text party">
                Sledeća žurka: <span className="location">TVRĐAVA 7. SEPTEMBAR</span>
              </p>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={imag}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </SliderContainer>

          <SliderContainer>
            <div className="inner">
              <p className="text title">Kragujevac</p>
              <div className="slider center">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg1.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(18)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg2.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(19)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg3.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(20)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg4.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(21)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg5.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(22)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/kg/kg6.jpg"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(23)}
                    />
                  </div>
                </Slider>
              </div>
              <p className="text party">
                Sledeća žurka: <span className="location">SAJAM 31. DECEMBAR</span>
              </p>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={imag}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </SliderContainer>

          <SliderContainer>
            <div className="inner" style={{ marginTop: 50 }}>
              <p className="text title">FESTIVAL</p>
              <div className="slider center">
                <Slider {...settings}>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/1.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(24)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/2.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(25)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/3.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(26)}
                    />
                  </div>
                  <div>
                    <img
                      loading="lazy"
                      src="/images/city/4.webp"
                      alt="1"
                      className="partyImg"
                      onClick={() => openImageViewer(27)}
                    />
                  </div>
                </Slider>
              </div>
              <p className="text party">
                Sledeća žurka: <span className="location">2025.</span>
              </p>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={imag}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </SliderContainer>
        </PartyContainer>
      </Element>
    </PartyWrapper>
  );
};
