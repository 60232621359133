import React, { useEffect, useState } from "react";
import { FooterContainer } from "./indes.style";
import { Link } from "react-scroll";
import { Link as Anchor } from "react-router-dom";

interface Props {
  modal: boolean;
  setModal: (e: boolean) => void;
}

export const Footer: React.FC<Props> = ({ modal, setModal }) => {
  useEffect(() => {
    document.body.addEventListener("click", () => {
      setModal(false);
    });
  });

  return (
    <FooterContainer>
      <div className="footer_bg">
        <div className="footer_content">
          <div className="left_content">
            <div>
              <Link to="home" spy={true} smooth={true} activeClass="active" offset={-50}>
                <p>Početna</p>
              </Link>
              <Link to="o-nama" spy={true} smooth={true} activeClass="active" offset={-50}>
                <p>O nama</p>
              </Link>
              <Link to="zurke" spy={true} smooth={true} activeClass="active">
                <p>Žurke</p>
              </Link>
              <Link to="festival" spy={true} smooth={true} activeClass="active">
                <p>Festival</p>
              </Link>
            </div>
            <div>
              <Anchor to="/ispovesti">Ispovesti</Anchor>

              <a href="https://www.pinterest.com/dragibravo/" target="_blank">
                PHOTO
              </a>
              <a href="https://www.youtube.com/@dragibravo/" target="_blank">
                Video
              </a>
            </div>
          </div>
          <div className="middle_content">
            <div>
              <img src="/images/icons/logo.webp" alt="logo" />
            </div>
            <p>Sva prava su zadržana. Dragi Bravo &reg;</p>
          </div>
          <div className="right_content">
            <div>
              <div className="partners">
                <div className="icon">
                  <img src="/images/icons/5.webp" alt="icon" />
                </div>
                <div className="icon">
                  <img src="/images/icons/6.webp" alt="icon" />
                </div>
                <div className="icon">
                  <img src="/images/icons/7.webp" alt="icon" />
                </div>
                <div className="icon">
                  <img src="/images/icons/8.webp" alt="icon" />
                </div>
                <div className="icon">
                  <img src="/images/icons/9.webp" alt="icon" />
                </div>
                <div className="icon">
                  <img src="/images/icons/pepsi.png" alt="icon" />
                </div>
              </div>
            </div>
            <div className="media_icons desktop">
              <p>Zapratite nas: </p>
              <div className="icon">
                <a href="https://www.instagram.com/dragibravo/" target="_blank">
                  <img src="/images/icons/14.webp" alt="icon" />
                </a>
              </div>
              <div className="icon">
                <a href="https://www.tiktok.com/@dragi_bravo" target="_blank">
                  <img src="/images/icons/15.webp" alt="icon" />
                </a>
              </div>
              <div className="icon">
                <a href="https://www.youtube.com/@dragibravo/" target="_blank">
                  <img src="/images/icons/16.webp" alt="icon" />
                </a>
              </div>

              <div className="icon">
                <a href="https://www.pinterest.com/dragibravo/" target="_blank">
                  <img src="/images/icons/19.webp" alt="icon" />
                </a>
              </div>
            </div>

            <div className="media_icons_mobile">
              <div>
                <p>Zapratite nas: </p>
              </div>
              <div>
                <div className="icon">
                  <a href="https://www.instagram.com/dragibravo/" target="_blank">
                    <img src="/images/icons/14.webp" alt="icon" />
                  </a>
                </div>
                <div className="icon">
                  <a href="https://www.tiktok.com/@dragi_bravo" target="_blank">
                    <img src="/images/icons/15.webp" alt="icon" />
                  </a>
                </div>
                <div className="icon">
                  <a href="https://www.youtube.com/@dragibravo/" target="_blank">
                    <img src="/images/icons/16.webp" alt="icon" />
                  </a>
                </div>

                <div className="icon">
                  <a href="https://www.pinterest.com/dragibravo/" target="_blank">
                    <img src="/images/icons/19.webp" alt="icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};
