import React, { useEffect, useRef, useState } from "react";
import { ShareModal } from "./ShareModal";

interface CommentProps {
  commentId: number;
  date: string;
  text: string;
  info1: number;
  info2: number;
  tema: string;
  scrolled: boolean;
  handleComment: (id: number, liked: boolean, disliked: boolean) => void;
  items: any;
  success: any;
  disliked: any;
  canClick: boolean;
}

export const SingleComment: React.FC<CommentProps> = ({
  commentId,
  date,
  text,
  info1,
  info2,
  tema,
  handleComment,
  items,
  success,
  disliked,
  canClick,
}) => {
  const [share, setShare] = useState(false);
  const [likesCount, setlikesCount] = useState(info1);
  const [dislikesCount, setDilikesCount] = useState(info2);

  const handleLikes = () => {
    if (likesCount < info1 + 1) {
      setlikesCount(likesCount + 1);
    }
  };

  const handleDislikes = () => {
    if (dislikesCount < info2 + 1) {
      setDilikesCount(dislikesCount + 1);
    }
  };

  console.log(canClick);

  return (
    <div className="single_wrapper" id={`${commentId}`}>
      <img
        src="/images/ispovesti/spiral.svg"
        alt="spirala"
        className="spiral"
      />
      <div className="inner_comment">
        <div className="comment_label">
          <p>#{commentId}</p>
          <p>(Tema: {tema}) </p>
        </div>
        <div>
          <div className="comment_content">{text}</div>
          <div className="more_comment"></div>
        </div>
        <hr className="divider" />
        <div className="comment_footer">
          <div className="comment_info">
            <span
              style={
                disliked && !success
                  ? { pointerEvents: "none" }
                  : { pointerEvents: "auto" }
              }
              className="info_1"
              id={`liked_${success}`}
              onClick={() => {
                canClick && handleComment(commentId, true, false);
                handleLikes();
              }}
            >
              {" "}
              <img src="/images/ispovesti/green-triangle.svg" alt="triangle" />
              {likesCount}
            </span>

            <span
              style={
                success && !disliked
                  ? { pointerEvents: "none" }
                  : { pointerEvents: "auto" }
              }
              className="info_2"
              id={`disliked_${disliked}`}
              onClick={() => {
                canClick && handleComment(commentId, false, true);
                handleDislikes();
              }}
            >
              <img src="/images/ispovesti/red-triangle.svg" alt="triangle" />
              {dislikesCount}
            </span>
          </div>
          <div></div>
          <div onClick={() => setShare(true)}>
            <img
              src="/images/ispovesti/copy.svg"
              height={38}
              width={38}
              alt="copy"
            />
          </div>
        </div>
        {share && <ShareModal setShare={setShare} id={commentId} />}
      </div>
    </div>
  );
};
