import styled from "styled-components";

interface Props {
  scrolling?: any;
  isViewerOpen?: boolean;
  active?: boolean;
}

export const NavBarContainer = styled.div<Props>`
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #ffa63b, #dd5e07);
  position: sticky;
  top: 0;
  z-index: ${(props) => (props.isViewerOpen ? "-1" : "99")};

  .modal {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    height: calc(100vh + 120px);
    position: absolute;
    top: -50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
      width: 300px;
      background-color: white;
      border-radius: 16px;
      padding: 48px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: bold;
    }
  }

  & p {
    margin: 0;
  }

  .inner_container {
    max-width: 1200px;
    margin: 0 auto;

    .mobile_menu {
      display: none;
    }

    .logo {
      & img {
        width: 140px;
        height: 126px;
        position: absolute;
        top: ${(props) => (props.scrolling ? "-16px" : "-58px")};
        cursor: pointer;
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      margin-left: 150px;
      ul {
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding-left: 0px;

        & div {
          height: 50px;
          display: flex;
          align-items: center;

          .active {
            color: #1192c9;
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);

            li {
              text-shadow: none;
              font-weight: bold;
            }
          }

          li {
            padding: 15px 20px;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold;
            text-shadow: black 1px 0 10px;
            &:hover {
              box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
              text-shadow: unset;
            }
          }

          a {
            text-decoration: none;
            color: #ffffff;
            font-weight: bold;
          }

          & a:hover {
            color: #1192c9;
          }
        }
      }

      .media_icons {
        display: flex;
        height: 50px;
        gap: 8px;
        align-items: center;
        .icon {
          display: flex;
          cursor: pointer;
          a {
            height: 25px;
            width: 25px;
          }
          img {
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .menuWrap {
      height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      // margin-top: ${(props) => (props.scrolling ? "0px" : "-50px")};
      position: absolute;
      top: 0;
      background-color: #2f3030;
      max-width: 300px;
      width: 100%;
      z-index: 10;
      transition: transform 0.8s ease;

      .menuM {
        background-color: #fff;
        color: white;
        z-index: 10;
        height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);

        .logo_mobile {
          img {
            height: 120px;
            width: 130px;
          }
        }

        .close_icon {
          padding-right: 20px;
          display: flex;
          justify-content: flex-end;
        }

        .nav_footer {
          position: absolute;
          bottom: 80px;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 8px;
          align-items: center;
          // padding-bottom: 80px;

          & > p {
            font-size: 14px;
            font-weight: bold;
          }

          .media_icons {
            display: flex;
            height: 50px;
            gap: 8px;
            align-items: center;
            // margin-right: 24px;
            .icon {
              cursor: pointer;
              a {
                height: 25px;
                width: 25px;
              }
              img {
                height: 25px;
                width: 25px;
              }
            }
          }
        }
      }

      ul {
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding-left: 8px;
        padding-top: 20px;
        width: 100%;
        background-color: #dd5e07;
        height: 100%;
        text-transform: uppercase;
      }

      li {
        padding: 10px 10px 10px 20px;
        font-size: 20px;
        font-weight: bold;
      }

      a {
        text-decoration: none;
        color: #fff;
      }
    }
    .inner_container {
      .logo {
        & img {
          top: -64px;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
      .nav {
        display: none;
      }

      .mobile_menu {
        display: flex;
        justify-content: flex-end;
        height: 50px;
        align-items: center;

        & div {
          margin-top: 3px;
        }

        & > div:last-of-type {
          padding-right: 16px;
          margin-top: 6px;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .menuWrap {
      width: 100%;
      max-width: unset;

      .menuM {
        width: 100%;
        .nav_footer {
          position: absolute;
          bottom: 60px;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 8px;
          align-items: center;
          padding-bottom: 20px;

          & > p {
            font-size: 14px;
            font-weight: bold;
          }

          .media_icons {
            display: flex;
            height: 50px;
            gap: 8px;
            align-items: center;
            // margin-right: 24px;
            .icon {
              cursor: pointer;
              a {
                height: 25px;
                width: 25px;
              }
              img {
                height: 25px;
                width: 25px;
              }
            }
          }
        }
        ul {
          width: 100%;
          li {
            max-width: 100px;
          }
        }
      }
    }

    .inner_container {
      .logo {
        & img {
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
    }
  }
`;
