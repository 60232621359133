import { FC, useEffect, useState } from "react";
import { FestivalContainer } from "./Festival.style";
import { Fade } from "react-awesome-reveal";
import simpleParallax from "simple-parallax-js";
import { Link } from "react-scroll";

interface Props {
  isViewerOpen: boolean;
}

export const Festival: FC<Props> = ({ isViewerOpen }) => {
  const image = document.getElementsByClassName("fest_image");
  new simpleParallax(image, {
    orientation: "up",
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  return (
    <FestivalContainer isViewerOpen={isViewerOpen}>
      {/* <div className="bg_image">
        <img src="images/fest.webp" className="fest_image" />
      </div> */}
      <Fade triggerOnce>
        <div>
          <div className="fest">
            <img src="/images/logo.png" alt="festival" />
          </div>
          {/*  <div className="text">
            <h1>KALEMEGDAN TVRĐAVA</h1>
            <h2>29. JUN, 30. JUN, 1. JUL</h2>
          </div> */}

          <div className="partners">
            <div className="icon">
              <img src="/images/icons/5.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/6.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/7.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/8.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/9.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/pepsi.png" alt="icon" />
            </div>
          </div>
          <div className="btn">
            <Link
              to="lineup"
              spy={true}
              smooth={true}
              activeClass="active"
              offset={width > 1000 ? 150 : 400}
            >
              <button>IZVOĐAČI FESTIVALA</button>
            </Link>
          </div>
        </div>
      </Fade>
      <div className="overlay" />
    </FestivalContainer>
  );
};
