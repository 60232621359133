import React, { useEffect, useRef, useState } from "react";
import { ModalWrapper } from "./index.style";

interface Props {
  setAddTestemonial: (e: boolean) => void;
  setThankYou: (e: boolean) => void;
}

export const Modal: React.FC<Props> = ({ setAddTestemonial, setThankYou }) => {
  const refs = useRef<HTMLDivElement>(null);
  const [tema, setTema] = useState(false);
  const [chosenTeme, setChosenTeme] = useState("Izaberi temu");
  const [testemonial, setTestemonial] = useState("");
  const [error, setError] = useState("");

  const handleClickOutside = (e: any) => {
    if (refs.current && !refs.current.contains(e.target)) {
      setAddTestemonial(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const begin = "Dragi bravo,";
  const message = `${begin}\r\n${testemonial}`;

  useEffect(() => {
    const focusElement = () => {
      let text = document.getElementById(`area`) as HTMLInputElement;
      if (text !== null && text.textContent !== null) {
        let pos = text?.textContent.indexOf(`\n`);
        text.setSelectionRange(pos + 1, pos + 1);
        text.focus();
      }
    };

    focusElement();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    const value = (document.getElementById("area") as HTMLInputElement).value;

    if (value.length < 23 || chosenTeme === "Izaberi temu") {
      setError(
        "Ispovest mora imati barem 10 karaktera da bi bila poslata i tema mora biti izabrana."
      );

      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      fetch("https://dragibravo.rs/api/ispovesti", {
        method: "POST",
        body: JSON.stringify({
          content: testemonial,
          topic: chosenTeme,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setAddTestemonial(false);
          setThankYou(true);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  return (
    <ModalWrapper>
      <div className="single_wrapper">
        <img
          src="/images/ispovesti/spiral.svg"
          alt="spirala"
          className="spiral"
        />
        <div className="inner_comment" ref={refs}>
          <textarea
            id="area"
            rows={15}
            name="awesome"
            maxLength={1000}
            onChange={(e) => setTestemonial(e.target.value)}
          >
            {message}
          </textarea>
          {error && (
            <p style={{ fontSize: "12px", color: "#ff4d4d" }}>{error}</p>
          )}
          <div className="modal_footer">
            <label
              className="dropdown"
              onClick={() => {
                setTema(!tema);
              }}
            >
              <div className="dd-button">{chosenTeme}</div>

              {tema ? (
                <img
                  src="/images/icons/open-cat.png"
                  className="dd-input"
                  id="test"
                  alt="arrow"
                />
              ) : (
                <img
                  src="/images/icons/open.svg"
                  className="dd-input"
                  id="test"
                  alt="arrow"
                />
              )}

              {tema && (
                <ul className="dd-menu">
                  <li onClick={() => setChosenTeme("Svaštara")}>Svaštara</li>
                </ul>
              )}
            </label>
            <button
              onClick={(e) => {
                handleClick(e);
              }}
              className="add_testemonial"
            >
              Pošalji
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
