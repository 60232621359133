import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-image: url("/images/footer-bg.webp");
  background-size: cover;
  margin-top: -90px;
  position: relative;
  z-index: 1;
  height: 350px;

  .modal {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    height: calc(100vh + 120px);
    position: absolute;
    top: -50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
      width: 300px;
      background-color: white;
      border-radius: 16px;
      padding: 48px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: bold;
    }
  }

  & p {
    margin: 0;
  }

  .footer_bg {
    height: 350px;
    margin-top: -10px;
    position: relative;
    z-index: 1;

    & > img:nth-of-type(1) {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: -1;
      height: 350px;
    }

    .footer_content {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: white;

      .left_content {
        display: flex;
        gap: 90px;
        padding-bottom: 75px;
        width: 400px;
        text-transform: uppercase;

        & a {
          cursor: pointer;
          text-decoration: none;
          color: white;
        }

        & > div {
          display: flex;
          flex-direction: column;
          gap: 10px;

          & a:hover,
          p:hover {
            color: #1192c9;
            text-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
          }
          & p {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }

      .middle_content {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        & img {
          width: 200px;
        }

        p {
          font-size: 16px;
        }
      }

      .right_content {
        padding-bottom: 70px;
        width: 500px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .media_icons {
          display: flex;
          height: 50px;
          gap: 15px;
          align-items: center;
          .icon {
            display: flex;
            cursor: pointer;
            img {
              height: 35px;
              width: 35px;
            }
          }
        }

        .media_icons_mobile {
          display: none;
        }

        .partners {
          //width: 550px;
          display: flex;
          justify-content: space-between;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            &:nth-of-type(5) {
              img {
                height: 30px;
              }
            }

            img {
              height: 45px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    height: 800px;
    margin-top: -115px;
    .footer_bg {
      height: 800px;
      .footer_content {
        padding: 0 16px;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-end;

        .left_content {
          width: 100%;
          justify-content: center;
          padding-bottom: 50px;
        }

        .middle_content {
          padding-bottom: 20px;
          flex-direction: column;
        }

        .right_content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          padding-bottom: 20px;

          .desktop {
            display: none;
          }

          .partners {
            //width: 550px;
            display: flex;
            flex-wrap: wrap;

            & > div:nth-of-type(1) {
              margin-bottom: 20px;
            }

            & > div:nth-of-type(2) {
              margin-bottom: 20px;
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:nth-of-type(5) {
                img {
                  height: 40px;
                }
              }

              img {
                height: 45px;
              }
            }
          }

          .media_icons_mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            & > div:nth-of-type(2) {
              display: flex;
              gap: 12px;
              flex-wrap: wrap;

              .icon {
                display: flex;
                cursor: pointer;
                img {
                  height: 35px;
                  width: 35px;
                }
              }
            }
          }
        }

        & > div:nth-of-type(1) {
          order: 1;
        }

        & > div:nth-of-type(2) {
          order: 3;
        }

        & > div:nth-of-type(3) {
          order: 2;
        }
      }
    }
  }

  @media screen and (min-width: 421px) and (max-width: 600px) {
    height: 800px;
    margin-top: -115px;
    .footer_bg {
      height: 800px;
      .footer_content {
        padding: 0 16px;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-end;

        .left_content {
          width: 100%;
          justify-content: center;
          padding-bottom: 30px;
        }

        .middle_content {
          padding-bottom: 20px;
        }

        .right_content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          padding-bottom: 20px;
          .partners {
            //width: 550px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;

            & > div:nth-of-type(1) {
              margin-bottom: 20px;
            }

            & > div:nth-of-type(2) {
              margin-bottom: 20px;
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:nth-of-type(5) {
                img {
                  height: 40px;
                }
              }

              img {
                height: 60px;
              }
            }
          }
        }

        & > div:nth-of-type(1) {
          order: 1;
        }

        & > div:nth-of-type(2) {
          order: 3;
        }

        & > div:nth-of-type(3) {
          order: 2;
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 750px) {
    height: 700px;
    margin-top: -115px;
    .footer_bg {
      height: 700px;
      .footer_content {
        padding: 0 16px;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-end;

        .left_content {
          width: 100%;
          justify-content: center;
          padding-bottom: 30px;
        }

        .middle_content {
          padding-bottom: 20px;
        }

        .right_content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 0;
          .partners {
            //width: 550px;
            display: flex;
            justify-content: space-between;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;

              &:nth-of-type(5) {
                img {
                  height: 30px;
                }
              }

              img {
                height: 80px;
              }
            }
          }
        }

        & > div:nth-of-type(1) {
          order: 1;
        }

        & > div:nth-of-type(2) {
          order: 3;
        }

        & > div:nth-of-type(3) {
          order: 2;
        }
      }
    }
  }

  @media screen and (min-width: 751px) and (max-width: 1024px) {
    height: 500px;
    margin-top: -110px;
    .footer_bg {
      height: 500px;
      .footer_content {
        padding: 0 16px;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-end;

        .left_content {
          padding-bottom: 0;
          width: 300px;
        }

        .right_content {
          padding-bottom: 0;
        }

        .middle_content {
          padding-bottom: 0;
          flex-direction: column-reverse;
          padding-bottom: 20px;
        }

        & div:nth-of-type(1) {
          order: 1;
        }

        & div:nth-of-type(2) {
          order: 3;
        }

        & div:nth-of-type(3) {
          order: 2;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: -120px;
    .footer_bg {
      .footer_content {
        .middle_content {
          padding-bottom: 0;
          flex-direction: column;
          padding-bottom: 20px;
        }
        padding: 0 16px;
        & div:nth-of-type(1) {
          // order: 1;
        }

        & div:nth-of-type(2) {
          order: 2;
        }

        & > div:nth-of-type(3) {
          order: 3;
          & > div {
            .partners {
              & div {
                order: unset;
              }
            }
          }
          .media_icons,
          .desktop {
            & div {
              order: unset;
            }
          }
        }
      }
    }
  }
`;
