import { useEffect, FC, useRef, useState } from "react";
import { AboutContainer } from "./AboutUs.style";
import { useInView } from "react-intersection-observer";
import { Slide } from "react-awesome-reveal";
import { Element } from "react-scroll";
import { FadeIn } from "react-slide-fade-in";

interface Props {
  isViewerOpen: boolean;
}

export const AboutUs: FC<Props> = ({ isViewerOpen }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  const videoRef = useRef<any>(null);
  const { ref, inView } = useInView({
    threshold: width > 700 ? 0.7 : 0.2,
  });

  useEffect(() => {
    if (inView) {
      videoRef.current.play();
    } else {
      if (!inView) {
        videoRef.current.pause();
      }
    }
  }, [inView]);

  return (
    <AboutContainer isViewerOpen={isViewerOpen}>
      <div className="tv_wrapper">
        <div className="tv">
          <div className="circle">
            <FadeIn
              from="bottom"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <div>
                <img src="/images/21.webp" alt="circle" />
              </div>
            </FadeIn>
          </div>
          <FadeIn
            from="left"
            positionOffset={400}
            triggerOffset={-200}
            delayInMilliseconds={0}
          >
            <div className="triangle1">
              <img src="/images/20.webp" alt="circle" />
            </div>
          </FadeIn>
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={-200}
            delayInMilliseconds={0}
          >
            <div className="triangle2">
              <img src="/images/20.webp" alt="circle" />
            </div>
          </FadeIn>
          <FadeIn
            from="left"
            positionOffset={400}
            triggerOffset={-200}
            delayInMilliseconds={0}
          >
            <div className="triangle3">
              <img src="/images/20.webp" alt="circle" />
            </div>
          </FadeIn>
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={-200}
            delayInMilliseconds={0}
          >
            <div className="triangle4">
              <img src="/images/20.webp" alt="circle" />
            </div>
          </FadeIn>
          <Element name="o-nama">
            <div className="videotv" ref={ref}>
              <div className="tv_frame">
                <img src="/images/tv.webp" alt="tv" />
              </div>

              <video muted loop ref={videoRef} playsInline>
                <source src="/images/videos/about.mp4" type="video/mp4" />
              </video>
            </div>
          </Element>
        </div>
      </div>
    </AboutContainer>
  );
};
