import styled from "styled-components";

export const Container = styled.div`
  min-height: 1000px;
  background-image: url("images/ispovesti/grey.png");

  .comments_container {
    min-height: 100vh;
  }

  & footer {
    margin-top: 0px;
  }
`;
