import styled from "styled-components";

interface Props {
  isViewerOpen?: boolean;
}

export const LineUpWrapper = styled.div<Props>`
  background-image: url("images/disks/pozadina.webp");
  background-size: cover;
  display: flex;
  height: 1400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: -280px;
  font-family: "DM Sans";
  color: white;
  font-size: 22px;
  text-shadow: 0px 2px 4px #00193d;
  font-weight: bolder;

  .lineup {
    //  max-width: 1400px;
    margin: 0 auto;
    display: flex;
    gap: 64px;
    padding-top: 120px;
    flex-direction: column;
    & img {
      height: 200px;
    }

    .first_lineup {
      display: flex;
      align-items: center;
      gap: 32px;
      //width: 1370px;
      padding: 0 16px;
      .disks {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;

        .disk {
          width: 200px;

          & p {
            margin-top: 0px;
          }
        }
      }

      .date {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: -300px;
        width: 200px;
        & p:nth-of-type(1) {
          font-size: 30px;
          text-transform: uppercase;
          margin: 0;
        }

        & p:nth-of-type(2) {
          font-size: 42px;
          text-transform: uppercase;
          color: #0969a3;
          text-shadow: none;
          font-weight: bolder;
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: 2700px;
    margin-top: -520px;
    .lineup {
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 450px;
      gap: 64px;
      & img {
        height: 180px;
      }
      .first_lineup {
        display: flex;
        flex-direction: column;

        .disks {
          display: flex;
          max-width: 360px;
          flex-wrap: wrap;
          column-gap: 16px;
          row-gap: 8px;
          justify-content: center;

          .disk {
            width: 167px;

            & p {
              margin: 0;
            }
          }
        }

        .date {
          text-align: center;
          align-items: center;
          & p:nth-of-type(1) {
            font-size: 30px;
            text-transform: uppercase;
            margin: 0;
          }

          & p:nth-of-type(2) {
            font-size: 42px;
            text-transform: uppercase;
            color: #0969a3;
            text-shadow: none;
            font-weight: bolder;
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 910px) {
    height: 3000px;
    margin-top: -620px;

    .lineup {
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 500px;

      .first_lineup {
        display: flex;
        flex-direction: column;
        .disks {
          width: 420px;
          justify-content: center;
        }
        .date {
          align-items: center;
        }
      }
    }
  }

  @media screen and (min-width: 911px) and (max-width: 1380px) {
    height: 2000px;
    margin-top: -400px;

    .lineup {
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 250px;
    }
  }

  @media screen and (min-width: 2000px) {
    margin-top: -420px;
  }
`;
