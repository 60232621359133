import styled from "styled-components";

export const CountContainer = styled.div`
  width: 100%;
  height: 50px;
  background-image: url("/images/3.webp");
  background-position: center;
  background-size: cover;

  & > div:nth-of-type(1) {
    display: flex;
    justify-content: flex-end;
    color: white;
    gap: 8px;

    & p {
      font-weight: bold;
    }
  }

  .flip-countdown {
    --background-color: #e30613;
    --text-color: #fff;
    margin: unset;
    & > span:nth-of-type(1) {
      display: none !important;
    }

    & > span:nth-of-type(2) {
      display: none !important;
      .flip-countdown-title {
        font-size: 0;
      }
    }

    & > span:nth-of-type(3) {
      .flip-countdown-title {
        font-size: 0;
      }
    }
    & > span:nth-of-type(4) {
      .flip-countdown-title {
        font-size: 0;
      }
    }
    & > span:nth-of-type(5) {
      .flip-countdown-title {
        font-size: 0;
      }
    }
    & > span:nth-of-type(6) {
      .flip-countdown-title {
        font-size: 0;
      }
    }
  }

  & > div {
    color: white;
    height: 50px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .flip-countdown-piece {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .flip-countdown-title {
        margin-left: 8px;
      }

      .flip-countdown-card {
        //  margin-top: 3px;
        & span {
          box-shadow: unset !important;
          .card__bottom {
            top: unset !important;
          }
        }
      }
    }

    & div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bg {
      font-size: 28px;
      margin-right: 2px;

      -webkit-text-stroke: 1px #fff;
      -webkit-text-fill-color: white;
    }
  }

  .flip-countdown-piece:nth-of-type(2)::before {
    content: "meseca";
    font-size: 18px;
    font-weight: bold;
    margin-left: -5px;
  }

  .flip-countdown-piece:nth-of-type(3)::before {
    content: "dana";
    font-size: 18px;
    font-weight: bold;
    margin-left: -5px;
  }
  .flip-countdown-piece:nth-of-type(4)::before {
    content: "sati";
    font-size: 18px;
    font-weight: bold;
    margin-left: -5px;
  }
  .flip-countdown-piece:nth-of-type(5)::before {
    content: "minuta";
    font-size: 18px;
    font-weight: bold;
    margin-left: -5px;
  }
  .flip-countdown-piece:nth-of-type(6)::before {
    content: "sekundi";
    font-size: 18px;
    font-weight: bold;
    margin-left: -5px;
  }

  .flip-countdown-card-sec,
  .one {
    font-size: 20px !important;

    .card__top,
    .card__bottom {
      background: #e30613 !important;
      color: #fff !important;
    }
  }

  @media screen and (max-width: 600px) {
    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      color: white;
      gap: 8px;
      flex-wrap: wrap;

      & p {
        // display: none;
      }
    }

    .flip-countdown-piece:nth-of-type(3)::before {
      content: "dana";
      font-size: 12px;
      font-weight: bold;
    }
    .flip-countdown-piece:nth-of-type(4)::before {
      content: "sati";
      font-size: 12px;
      font-weight: bold;
    }
    .flip-countdown-piece:nth-of-type(5)::before {
      content: "min";
      font-size: 12px;
      font-weight: bold;
    }
    .flip-countdown-piece:nth-of-type(6)::before {
      content: "sek";
      font-size: 12px;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1200px) {
    & > div:nth-of-type(1) {
      padding-right: 16px;
    }
  }
`;
