import styled from "styled-components";

interface Props {
  isViewerOpen?: boolean;
}

export const AboutContainer = styled.div<Props>`
  height: 1100px;
  // height: 100vh;
  background-image: url("/images/about-us.webp");
  background-size: cover;
  position: relative;
  z-index: ${(props) => (props.isViewerOpen ? "-1" : 10)};
  padding-top: 100px;
  // margin-top: -80px;
  overflow: hidden;

  & p {
    margin: 0;
  }

  .tv_wrapper {
    height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    .tv {
      position: relative;
      max-width: 700px;
      z-index: 2;

      & > div {
        z-index: -10;
      }

      .triangle {
        position: relative !important;
        z-index: -1 !important;
      }

      & > video {
        max-width: 500px;
      }

      .videotv {
        position: relative;
        .tv_frame {
          img {
            max-width: 700px;
          }
        }
        & > video {
          position: absolute;
          max-width: 590px;
          top: 64px;
          left: 56px;
          z-index: -1;
        }
      }
    }

    .circle {
      position: absolute;
      bottom: 20px;
      left: -150px;
      z-index: -1;

      img {
        height: 300px;
      }
    }

    .triangle1 {
      position: absolute;
      top: -50px;
      right: -150px;
      z-index: -2;
      overflow: hidden;
      img {
        height: 300px;
        position: relative;
        z-index: -10;
      }
    }
    .triangle2 {
      position: absolute;
      top: 20px;
      right: -250px;
      overflow: hidden;
      img {
        height: 70px;
      }
    }
    .triangle3 {
      position: absolute;
      top: 116px;
      right: -290px;
      transform: rotate(312deg);
      overflow: hidden;
      img {
        height: 50px;
      }
    }
    .triangle4 {
      position: absolute;
      top: 135px;
      right: -165px;
      transform: rotate(257deg);
      overflow: hidden;
      img {
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: -245px;
    margin-left: -130px;
    height: 900px;
    .tv_wrapper {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0px;
      margin-top: 80px;
      margin-left: 130px;

      .circle {
        position: absolute;
        bottom: -80px;
        left: -45px;
        z-index: -1;

        img {
          height: 250px;
        }
      }

      .triangle1 {
        position: absolute;
        top: -70px;
        left: 112px;
        z-index: -2;
        right: 0px;
        transform: rotate(283deg);
        img {
          height: 200px;
        }
      }
      .triangle2 {
        position: absolute;
        top: -140px;
        left: 140px;
        right: 0px;
        img {
          height: 50px;
        }
      }
      .triangle3 {
        position: absolute;
        top: -130px;
        left: 220px;
        right: 0px;
        right: 0;
        transform: rotate(312deg);
        img {
          height: 80px;
        }
      }
      .triangle4 {
        position: absolute;
        top: -270px;
        left: 100px;
        right: 0px;
        transform: rotate(257deg);
        img {
          height: 30px;
        }
      }

      .tv {
        position: relative;
        max-width: 300px;
        z-index: 2;

        .videotv {
          position: relative;
          .tv_frame {
            img {
              max-width: 300px;
            }
          }
          & > video {
            position: absolute;
            top: 30px;
            left: 29px;
            z-index: -1;
            max-width: 245px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1300px) {
    height: 1200px;
    .tv_wrapper {
      height: 1200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .tv {
        position: relative;
        max-width: 600px;
        z-index: 2;

        .videotv {
          position: relative;
          margin-top: 120px;
          .tv_frame {
            img {
              max-width: 600px;
            }
          }
          & > video {
            position: absolute;
            top: 60px;
            left: 45px;
            z-index: -1;
            max-width: 500px;
          }
        }
      }
      .triangle1 {
        position: absolute;
        top: 54px;
        z-index: -2;
        right: 0px;
        left: unset;
        transform: rotate(283deg);
        img {
          height: 200px;
        }
      }
      .triangle2 {
        position: absolute;
        top: -28px;
        left: unset;
        right: 110px;
        img {
          height: 50px;
        }
      }
      .triangle3 {
        position: absolute;
        top: 0;
        left: unset;
        right: 0px;
        right: 0;
        transform: rotate(312deg);
        img {
          height: 80px;
        }
      }
      .triangle4 {
        position: absolute;
        top: -350px;
        left: 145px;
        transform: rotate(257deg);
        img {
          height: 30px;
        }
      }
    }
  }

  @media screen and (min-width: 2000px) {
    background-size: contain;
  }
`;
