import styled from "styled-components";

export const HomePageContainer = styled.div`
  height: calc(100vh);
  //background-image: url("/images/shadow.webp");
  //background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btns {
    display: flex;
    gap: 16px;
    position: relative;
    z-index: 12;
    .btn {
      margin-top: 40px;

      & a {
        text-decoration: none;
        color: white;
      }
      & button {
        border: none;
        outline: none;
        background: linear-gradient(to right, #ffa63b, #dd5e07);
        color: white;
        border-radius: 10px;
        padding: 12px 32px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .bgVideo {
    z-index: -1;
    position: fixed;
    filter: brightness(40%);
    top: 50px;
    //right: 0;
  }
  .event {
    .city {
      position: relative;

      .text {
        color: white;
        position: absolute;
        top: 60px;

        & p {
          margin: 0;
          text-transform: uppercase;
        }

        & p:nth-of-type(1) {
          font-weight: bold;
          font-size: 70px;
        }

        & p:nth-of-type(2) {
          font-size: 32px;
          text-align: left;
          font-weight: bold;
        }
      }
      .circle {
        position: relative;
        img {
          height: 350px;
        }
      }

      .orange1 {
        position: absolute;
        top: 135px;
        right: -52px;

        img {
          height: 220px;
        }

        .date {
          position: absolute;
          color: white;
          font-size: 38px;
          top: 50px;
          left: 20px;
        }
      }

      .orange2 {
        position: absolute;
        top: 165px;
        right: -140px;
        transform: rotate(245deg);

        img {
          height: 90px;
        }
      }

      .orange3 {
        position: absolute;
        top: 250px;
        right: -94px;
        transform: rotate(115deg);

        img {
          height: 60px;
        }
      }
    }

    .glow_line {
      img {
        height: 10px;
        width: 100%;
      }
    }
  }

  .ns_logo {
    & img {
      height: 500px;
    }
  }

  .text_express {
    max-width: 565px;

    color: white;
    & p:nth-of-type(1) {
      font-weight: bold;
      font-size: x-large;
    }

    & > p:nth-of-type(2) {
      text-align: center;
    }

    .highlight {
      padding: 5px 10px;
      border-radius: 10px;
      background-color: #1192c9;
    }

    .colored {
      color: #ec9852;
      font-weight: bold;
    }
  }

  .partners {
    width: 540px;
    display: flex;
    justify-content: space-between;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-of-type(5) {
        img {
          height: 50px;
        }
      }

      img {
        height: 50px;
      }
    }
  }

  .glow_wrapper {
    width: 500px;
    height: 50px;

    & img {
      width: 100%;
      height: 50px;
    }
  }

  @media screen and (max-width: 600px) {
    .ns_logo {
      margin-top: 140px;
      & img {
        height: 300px;
      }
    }
    .bgVideo {
      display: none;
    }

    height: calc(100vh + 145px);
    padding: 50px 0;
    background-image: url("/images/home-mobile.webp");
    background-size: cover;
    object-fit: cover;
    padding: 0px 0 150px 0;

    .event {
      .city {
        .circle {
          position: relative;
          img {
            height: 200px;
          }
        }

        .orange1 {
          right: 0px;
          top: 90px;
          & img {
            height: 120px;
          }

          .date {
            top: 30px;
            left: 12px;
            font-size: 20px;
          }
        }

        .orange2 {
          position: absolute;
          top: 95px;
          right: -40px;
          transform: rotate(245deg);

          img {
            height: 50px;
          }
        }

        .orange3 {
          position: absolute;
          top: 144px;
          right: -20px;
          transform: rotate(115deg);

          img {
            height: 30px;
          }
        }

        .text {
          top: 0px;
          left: 5px;
          text-align: left;
          & p:nth-of-type(1) {
            font-weight: bold;
            font-size: 48px;
            width: 300px;
          }

          & p:nth-of-type(2) {
            font-size: 22px;
            text-align: left;
          }
        }
      }
    }
    .text_express {
      max-width: 350px;
      padding: 0 16px;
      margin-top: 25px;

      color: white;
      & p:nth-of-type(1) {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        line-height: 36px;
        margin-top: 0px;
      }

      & > p:nth-of-type(2) {
        text-align: center;
        font-size: 14px;
        margin-bottom: 0px;
      }

      .highlight {
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #1192c9;
      }
    }

    .partners {
      width: unset;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 300px;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      gap: 14px;
      .icon {
        img {
          height: 60px;
        }
      }
    }

    .glow_wrapper {
      width: 100%;
      //height: unset;

      & img {
        width: 100%;
        height: unset;
      }
    }
  }

  @media screen and (max-width: 360px) {
    padding: 50px 0 100px 0;
  }

  @media screen and (max-width: 420px) {
    padding: 50px 0 100px 0;
    .ns_logo {
      margin-top: 0px;
      & img {
        height: 250px;
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1200px) {
    .ns_logo {
      margin-top: 140px;
      & img {
        height: 300px;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    height: 1000px;
    .bgVideo {
      width: 100vw;
      top: 0px;

      video {
        width: 100%;
      }
    }
  }
`;
