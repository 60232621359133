import React, { useEffect, useRef } from "react";
import { ShareModalWrapper } from "./index.style";

interface Props {
  setThankYou: (e: boolean) => void;
}

export const ThankYouModal: React.FC<Props> = ({ setThankYou }) => {
  const refs = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (refs.current && !refs.current.contains(e.target)) {
      setThankYou(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <ShareModalWrapper>
      <div className="container2" ref={refs}>
        <p>Hvala ti što si podelio/la to sa nama!</p>
        <button
          onClick={() => {
            setThankYou(false);
          }}
          className="add_testemonial"
        >
          Nema na čemu!
        </button>
      </div>
    </ShareModalWrapper>
  );
};
