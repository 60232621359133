import React, { Dispatch, SetStateAction, useState } from "react";

type ContextProps = {
  isViewerOpen: boolean;
  setIsViewerOpen: Dispatch<SetStateAction<boolean>>;
};

let BravoContext: any;
let { Provider } = (BravoContext = React.createContext<Partial<ContextProps>>(
  {}
));

let BravoProvider = ({ children }: { children: React.ReactNode }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <Provider value={{ isViewerOpen, setIsViewerOpen }}>{children}</Provider>
  );
};
export { BravoContext, BravoProvider };
