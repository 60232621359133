import React, { useEffect, useRef, useState } from "react";
import { CommentContainer, Wrapper } from "./index.style";
import { SingleComment } from "./SingleComment";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

interface Props {
  setAddTestemonial: (e: boolean) => void;
  addTestemonial: boolean;
  setThankYou: (e: boolean) => void;
  thankYou: boolean;
}

export const Comment: React.FC<Props> = ({
  addTestemonial,
  setAddTestemonial,
}) => {
  const [tema, setTema] = useState(false);
  const [sort, setSort] = useState(false);
  const [chosenTeme, setChosenTeme] = useState("Izaberi temu");
  const [chosenSort, setChosenSort] = useState("Najnovije");
  const [scrolled, setScrolled] = useState(false);
  const [commentsAPI, setComments] = useState([]);
  const [page, setPage] = useState(0);

  const url = `https://www.dragibravo.rs/api/ispovesti/${page}`;
  const fetchInfo = () => {
    return fetch(url)
      .then((res) => res.json())
      .then((data) => setComments(data));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const refs = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (refs.current && !refs.current.contains(e.target)) {
      setTema(false);
      setSort(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get("id");

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchId, setSearchId] = useState<any>();
  const [filtered, setFiltered] = useState<any>();

  useEffect(() => {
    setScrolled(true);
    const removeErrorParam = () => {
      if (searchParams.has("id")) {
        searchParams.delete("id");
        setSearchParams(searchParams);
      }
      if (searchParams.has("page")) {
        searchParams.delete("page");
        setSearchParams(searchParams);
      }
    };

    setTimeout(() => {
      removeErrorParam();
      setScrolled(false);
    }, 2500);
  }, []);

  useEffect(() => {
    setSearchId(id);
    const scrollToElement = () => {
      const element = searchId && document.getElementById(searchId);

      if (element) {
        element.classList.add("pulse");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => {
          element.classList.remove("pulse");
        }, 2500);
      }
    };

    scrollToElement();
  }, [searchId]);

  const [items, setItems] = useState<any>(
    JSON.parse(localStorage.getItem("items") as any) || []
  );

  useEffect(() => {
    const storedTodo = JSON.parse(localStorage.getItem("items") as any);
    if (storedTodo) setItems(storedTodo);
  }, []);

  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  const handleComment = (id: number, liked: boolean, disliked: boolean) => {
    const item = {
      id: id,
      liked: liked,
      disliked: disliked,
    };
    fetch(`https://dragibravo.rs/api/ispovesti/${id}`, {
      method: "POST",
      body: JSON.stringify({
        broj_osudjivanja: 1,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setItems((prevTodo) => {
          return [...prevTodo, item];
        });
      });
  };

  let pp = items.filter(
    (ele, ind) =>
      ind === items.findIndex((elem) => elem.commentId === ele.commentId)
  );

  /*   const mergeById = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.id === itm.id && item),
      ...itm,
    }));

  const merged = mergeById(comments, pp); */

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  var mergedList = _.map(commentsAPI, function (item) {
    return _.extend(
      item,
      _.find(getUnique(items, "IspovestID"), { id: item.IspovestID })
    );
  });

  return (
    <Wrapper active={tema} active2={sort}>
      <div className="ispovesti_header">
        <h1>Ispovesti</h1>
        <div className="filters" ref={refs}>
          <label
            className="dropdown"
            onClick={() => {
              setTema(!tema);
              setSort(false);
            }}
          >
            <div className="dd-button">{chosenTeme}</div>

            {tema ? (
              <img
                src="/images/icons/open-cat.png"
                className="dd-input"
                id="test"
                alt="arrow"
              />
            ) : (
              <img
                src="/images/icons/open.svg"
                className="dd-input"
                id="test"
                alt="arrow"
              />
            )}

            {tema && (
              <ul className="dd-menu">
                <li
                  className={`${
                    chosenTeme === "Svaštara" ? "active" : "inactive"
                  }`}
                  onClick={() => setChosenTeme("Svaštara")}
                >
                  Svaštara
                </li>
              </ul>
            )}
          </label>

          <label
            className="dropdown2"
            onClick={() => {
              setSort(!sort);
              setTema(false);
            }}
          >
            <div className="dd-button2">{chosenSort}</div>

            {sort ? (
              <img
                src="/images/icons/open-cat.png"
                className="dd-input"
                id="test"
                alt="arrow"
              />
            ) : (
              <img
                src="/images/icons/open.svg"
                className="dd-input"
                id="test"
                alt="arrow"
              />
            )}

            {sort && (
              <ul className="dd-menu2">
                <li
                  className={`${
                    chosenSort === "Najnovije" ? "active" : "inactive"
                  }`}
                  onClick={() => setChosenSort("Najnovije")}
                >
                  Najnovije
                </li>
                <li
                  className={`${
                    chosenSort === "Popularno" ? "active" : "inactive"
                  }`}
                  onClick={() => setChosenSort("Popularno")}
                >
                  Popularno
                </li>
                <li
                  className={`${
                    chosenSort === "Odobravanja" ? "active" : "inactive"
                  }`}
                  onClick={() => setChosenSort("Odobravanja")}
                >
                  Odobravanja
                  <img
                    src="/images/ispovesti/green-triangle.svg"
                    alt="triangle"
                  />
                </li>
                <li
                  className={`${
                    chosenSort === "Osuđivanja" ? "active" : "inactive"
                  }`}
                  onClick={() => setChosenSort("Osuđivanja")}
                >
                  Osuđivanja
                  <img
                    src="/images/ispovesti/red-triangle.svg"
                    alt="triangle"
                  />
                </li>
              </ul>
            )}
          </label>

          <button
            className="add_testemonial"
            onClick={() => setAddTestemonial(true)}
          >
            Napiši ispovest
          </button>
        </div>
      </div>
      <CommentContainer>
        {commentsAPI?.map((comment: any) => {
          return (
            <SingleComment
              commentId={comment.IspovestID}
              date={comment.DatumVreme}
              text={comment.Sadrzaj}
              info1={comment.broj_odobravanja}
              info2={comment.broj_osudjivanja}
              key={comment.IspovestID}
              tema={comment.Tema}
              scrolled={scrolled}
              handleComment={handleComment}
              items={items}
              success={comment.liked}
              disliked={comment.disliked}
              canClick={
                items.filter((i) => i.id === comment.IspovestID) &&
                items.length > 0
                  ? false
                  : true
              }
            />
          );
        })}
      </CommentContainer>
      {commentsAPI.length > 5 && (
        <div className="page_btns">
          <div className="prev_page_btn">
            <img
              src="/images/ispovesti/prev-page.svg"
              alt="next_page"
              height={8}
              width={17}
            />
          </div>
          <div className="next_page_btn">
            <img
              src="/images/ispovesti/next-page.svg"
              alt="next_page"
              width={170}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};
