import { useState, FC, useEffect, useLayoutEffect, useRef } from "react";
import { NavBarContainer } from "./index.style";
import { Link } from "react-scroll";
import { Link as Anchor } from "react-router-dom";

import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";

interface Props {
  isViewerOpen: boolean;
  modal: boolean;
  setModal: (e: boolean) => void;
}

export const NavBar: FC<Props> = ({ isViewerOpen, modal, setModal }) => {
  const [scrollPosition, setPosition] = useState(0);
  const [openPanel, setOpenPanel] = useState(false);

  useEffect(() => {
    if (openPanel) {
      document.body.style.overflow = "hidden";
    } else if (!openPanel) {
      document.body.style.overflow = "unset";
    }
  }, [openPanel]);

  function useWindowPosition() {
    useLayoutEffect(() => {
      function updatePosition() {
        setPosition(window.pageYOffset);
      }
      window.addEventListener("scroll", updatePosition);
      updatePosition();
      return () => window.removeEventListener("scroll", updatePosition);
    }, []);
    return scrollPosition;
  }

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setModal(false);
    });
  });

  return (
    <NavBarContainer
      scrolling={useWindowPosition() >= 50}
      isViewerOpen={isViewerOpen}
      active={openPanel}
    >
      <div className="inner_container">
        <Link to="home" spy={true} smooth={true} offset={-100}>
          <div className="logo">
            <img src={"/images/icons/logo.webp"} alt="logo" />
          </div>
        </Link>
        <nav className="nav">
          <ul className="nav-links">
            <div className="menu">
              <Link
                to="home"
                spy={true}
                smooth={true}
                activeClass="active"
                offset={-50}
              >
                <Anchor to="/">
                  <li>Početna</li>
                </Anchor>
              </Link>

              <Link
                to="o-nama"
                spy={true}
                smooth={true}
                activeClass="active"
                offset={-50}
              >
                <Anchor to="/">
                  <li>O nama</li>
                </Anchor>
              </Link>
              <Link
                to="zurke"
                spy={true}
                smooth={true}
                activeClass="active"
                offset={100}
              >
                <Anchor to="/">
                  <li>Žurke</li>
                </Anchor>
              </Link>
              <Link
                to="festival"
                spy={true}
                smooth={true}
                activeClass="active"
                offset={150}
              >
                <Anchor to="/">
                  <li>Festival</li>
                </Anchor>
              </Link>
              <li>
                <Anchor to="/ispovesti">Ispovesti</Anchor>
              </li>

              <li>
                <a href="https://www.pinterest.com/dragibravo/" target="_blank">
                  PHOTO
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@dragibravo/" target="_blank">
                  Video
                </a>
              </li>
            </div>
          </ul>
          <div className="media_icons">
            <div className="icon">
              <a href="https://www.instagram.com/dragibravo/" target="_blank">
                <img src="/images/icons/14.webp" alt="icon" />
              </a>
            </div>
            <div className="icon">
              <a href="https://www.tiktok.com/@dragi_bravo" target="_blank">
                <img src="/images/icons/15.webp" alt="icon" />
              </a>
            </div>
            <div className="icon">
              <a href="https://www.youtube.com/@dragibravo/" target="_blank">
                <img src="/images/icons/16.webp" alt="icon" />
              </a>
            </div>
            {/*  <div className="icon">
              <img src="/images/icons/17.webp" alt="icon" />
            </div>
            <div className="icon">
              <img src="/images/icons/18.webp" alt="icon" />
            </div> */}
            <div className="icon">
              <a href="https://www.pinterest.com/dragibravo/" target="_blank">
                <img src="/images/icons/19.webp" alt="icon" />
              </a>
            </div>
          </div>
        </nav>

        <div className="mobile_menu">
          <div onClick={() => setOpenPanel(true)}>
            {openPanel ? null : (
              <img
                src="/images/icons/hamburger.webp"
                alt="hamburger"
                height={30}
              />
            )}
          </div>
        </div>
      </div>
      <SlidingPanel
        type={"left"}
        isOpen={openPanel}
        size={60}
        backdropClicked={() => setOpenPanel(false)}
      >
        <div className={"menuWrap"}>
          <div className={"menuM"}>
            <ul>
              <div className="close_icon" onClick={() => setOpenPanel(false)}>
                <img
                  src="/images/icons/close.webp"
                  alt="hamburger"
                  height={30}
                />
              </div>
              <li>
                <Link
                  onClick={() => setOpenPanel(false)}
                  to="home"
                  spy={true}
                  smooth={true}
                  activeClass="active"
                  offset={-50}
                >
                  <Anchor to="/">Početna</Anchor>
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => setOpenPanel(false)}
                  to="o-nama"
                  spy={true}
                  smooth={true}
                  activeClass="active"
                  offset={-100}
                >
                  <Anchor to="/">O nama</Anchor>
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => setOpenPanel(false)}
                  to="zurke"
                  spy={true}
                  smooth={true}
                  activeClass="active"
                  offset={-50}
                >
                  <Anchor to="/">Žurke</Anchor>
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => setOpenPanel(false)}
                  to="festival"
                  spy={true}
                  smooth={true}
                  activeClass="active"
                  offset={0}
                >
                  <Anchor to="/">Festival</Anchor>
                </Link>
              </li>
              <li>
                <Anchor to="/ispovesti">Ispovesti</Anchor>
              </li>

              <li>
                <a
                  onClick={() => {
                    setOpenPanel(false);
                  }}
                  href="https://www.pinterest.com/dragibravo/"
                  target="_blank"
                >
                  PHOTO
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setOpenPanel(false);
                  }}
                  href="https://www.youtube.com/@dragibravo/"
                  target="_blank"
                >
                  Video
                </a>
              </li>
            </ul>
            <div className="nav_footer">
              <div className="logo_mobile">
                <img src={"/images/icons/logo.webp"} alt="logo" />
              </div>
              <div className="media_icons">
                <div className="icon">
                  <a
                    href="https://www.instagram.com/dragibravo/"
                    target="_blank"
                  >
                    <img src="/images/icons/14.webp" alt="icon" />
                  </a>
                </div>
                <div className="icon">
                  <a href="https://www.tiktok.com/@dragi_bravo" target="_blank">
                    <img src="/images/icons/15.webp" alt="icon" />
                  </a>
                </div>
                <div className="icon">
                  <a
                    href="https://www.youtube.com/@dragibravo/"
                    target="_blank"
                  >
                    <img src="/images/icons/16.webp" alt="icon" />
                  </a>
                </div>

                <div className="icon">
                  <a
                    href="https://www.pinterest.com/dragibravo/"
                    target="_blank"
                  >
                    <img src="/images/icons/19.webp" alt="icon" />
                  </a>
                </div>
              </div>
              <p>Sva prava su zadržana. Dragi Bravo &reg;</p>
            </div>
          </div>
        </div>
      </SlidingPanel>
      {modal && (
        <div className="modal">
          <div className="box">Dolazi uskoro.</div>
        </div>
      )}
    </NavBarContainer>
  );
};
