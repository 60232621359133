import styled from "styled-components";

interface Props {
  isViewerOpen?: boolean;
}

export const FestivalContainer = styled.div<Props>`
  background-image: url("images/fest.webp");
  background-size: cover;
  display: flex;
  height: 1000px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: -250px;

  .overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    color: white;
    font-size: 20px;
    text-align: center;
  }

  & div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: ${(props) => (props.isViewerOpen ? "-1" : "10")};
    .btn {
      margin-top: 40px;
      & a {
        text-decoration: none;
        color: white;
      }
      & button {
        border: none;
        outline: none;
        background: linear-gradient(to right, #ffa63b, #dd5e07);
        color: white;
        border-radius: 10px;
        padding: 12px 32px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .fest {
      img {
        width: 600px;
        margin-bottom: 15px;
      }
    }

    .text {
      text-align: center;
      h1,
      h2 {
        margin: 0;
      }

      h1 {
        color: white;
        font-size: 48px;
        font-weight: bold;
        text-shadow: #ffa63b 1px 0 20px;
      }

      h2 {
        color: #ffa63b;
        font-weight: 900;
        font-size: 32px;
      }
    }

    .partners {
      //width: 550px;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-of-type(5) {
          img {
            height: 50px;
          }
        }

        img {
          height: 70px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    & div:nth-of-type(1) {
      .fest {
        img {
          width: 360px;
        }
      }
      .text {
        h1 {
          font-size: 28px;
        }

        h2 {
          font-size: 20px;
        }
      }
    }

    .text {
      padding: 0 16px;
      word-break: break-word;
    }
    & div:nth-of-type(1) {
      .partners {
        width: unset;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 300px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .icon {
          img {
            height: 50px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1300px) {
    & div:nth-of-type(1) {
      .partners {
        justify-content: center;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    height: 1300px;
  }
`;
